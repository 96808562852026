import { fetchRates } from "@/api";
import type { Rates } from "@/shared/types";

export const useRatesStore = defineStore("rates", () => {
  const rates = ref<Rates>({});
  const loading = ref(false);

  const getRates = async (silent = false) => {
    if (!silent) {
      loading.value = true;
    }
    const res = await fetchRates();
    rates.value = res;
    if (!silent) {
      loading.value = false;
    }
  };

  return { rates, loading, getRates };
});
