import { default as aboutKJAmqyq7bDMeta } from "/usr/src/app/pages/about.vue?macro=true";
import { default as _91sessionId_93xlb4grVyGrMeta } from "/usr/src/app/pages/administration/chats/[sessionId].vue?macro=true";
import { default as indexWiQLyUGRtPMeta } from "/usr/src/app/pages/administration/chats/index.vue?macro=true";
import { default as _91orderId_9312DDsEeZU3Meta } from "/usr/src/app/pages/administration/disputes/[orderId].vue?macro=true";
import { default as index5EXV895ZYhMeta } from "/usr/src/app/pages/administration/disputes/index.vue?macro=true";
import { default as indexnMNUgWRJc2Meta } from "/usr/src/app/pages/administration/index.vue?macro=true";
import { default as statsTyV4bWlsd2Meta } from "/usr/src/app/pages/administration/stats.vue?macro=true";
import { default as indexJlUehoice6Meta } from "/usr/src/app/pages/administration/users/index.vue?macro=true";
import { default as administrationDGZ6pUCGuuMeta } from "/usr/src/app/pages/administration.vue?macro=true";
import { default as beginners_45guideXY0ITcISlEMeta } from "/usr/src/app/pages/beginners-guide.vue?macro=true";
import { default as _91codeId_93sD1qCz1POHMeta } from "/usr/src/app/pages/blog/[codeId].vue?macro=true";
import { default as indexgewR7USAlvMeta } from "/usr/src/app/pages/blog/index.vue?macro=true";
import { default as emailIf5gHBoGy5Meta } from "/usr/src/app/pages/confirm/email.vue?macro=true";
import { default as faqDkOohHQxeIMeta } from "/usr/src/app/pages/faq.vue?macro=true";
import { default as helpQYzekt5ZcHMeta } from "/usr/src/app/pages/help.vue?macro=true";
import { default as indexgw2ikJsyhcMeta } from "/usr/src/app/pages/index.vue?macro=true";
import { default as loginRGcvZC480vMeta } from "/usr/src/app/pages/login.vue?macro=true";
import { default as newsT2RoX8WRasMeta } from "/usr/src/app/pages/news.vue?macro=true";
import { default as privicy_45policy1IBdBc5Nq2Meta } from "/usr/src/app/pages/privicy-policy.vue?macro=true";
import { default as _91userId_93s61DvyQgvIMeta } from "/usr/src/app/pages/profile/[userId].vue?macro=true";
import { default as ratesOxfGWLW8w0Meta } from "/usr/src/app/pages/rates.vue?macro=true";
import { default as registrationHsGWJw4mRLMeta } from "/usr/src/app/pages/registration.vue?macro=true";
import { default as reset_45passwordZej1Ub4tM0Meta } from "/usr/src/app/pages/reset-password.vue?macro=true";
import { default as support_45chatuHhkhatM9MMeta } from "/usr/src/app/pages/support-chat.vue?macro=true";
import { default as terms_45of_45usekooS9dlcBMMeta } from "/usr/src/app/pages/terms-of-use.vue?macro=true";
import { default as _91action_93uME32lIpIWMeta } from "/usr/src/app/pages/trade/[action].vue?macro=true";
import { default as chathvU3vFyJxPMeta } from "/usr/src/app/pages/trade/chat.vue?macro=true";
import { default as my_45offersh2j4Q6KatBMeta } from "/usr/src/app/pages/trade/my-offers.vue?macro=true";
import { default as _91orderId_93BAO5frnMflMeta } from "/usr/src/app/pages/trade/orders/[orderId].vue?macro=true";
import { default as indexmJAwutfoeWMeta } from "/usr/src/app/pages/trade/orders/index.vue?macro=true";
import { default as ordersUiYkFP5OU7Meta } from "/usr/src/app/pages/trade/orders.vue?macro=true";
import { default as profileTENEwI8GN2Meta } from "/usr/src/app/pages/trade/profile.vue?macro=true";
import { default as trade58egNtb0yCMeta } from "/usr/src/app/pages/trade.vue?macro=true";
import { default as index9wmPuxyvMJMeta } from "/usr/src/app/pages/user/index.vue?macro=true";
import { default as notificationsG4TyXarPOYMeta } from "/usr/src/app/pages/user/notifications.vue?macro=true";
import { default as settingsLKQwpF56qGMeta } from "/usr/src/app/pages/user/settings.vue?macro=true";
import { default as walletLFoxqnXK8jMeta } from "/usr/src/app/pages/user/wallet.vue?macro=true";
import { default as userYgr9W7cUghMeta } from "/usr/src/app/pages/user.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    meta: aboutKJAmqyq7bDMeta || {},
    component: () => import("/usr/src/app/pages/about.vue").then(m => m.default || m)
  },
  {
    name: administrationDGZ6pUCGuuMeta?.name,
    path: "/administration",
    meta: administrationDGZ6pUCGuuMeta || {},
    component: () => import("/usr/src/app/pages/administration.vue").then(m => m.default || m),
    children: [
  {
    name: "administration-chats-sessionId",
    path: "chats/:sessionId()",
    component: () => import("/usr/src/app/pages/administration/chats/[sessionId].vue").then(m => m.default || m)
  },
  {
    name: "administration-chats",
    path: "chats",
    component: () => import("/usr/src/app/pages/administration/chats/index.vue").then(m => m.default || m)
  },
  {
    name: "administration-disputes-orderId",
    path: "disputes/:orderId()",
    component: () => import("/usr/src/app/pages/administration/disputes/[orderId].vue").then(m => m.default || m)
  },
  {
    name: "administration-disputes",
    path: "disputes",
    component: () => import("/usr/src/app/pages/administration/disputes/index.vue").then(m => m.default || m)
  },
  {
    name: "administration",
    path: "",
    meta: indexnMNUgWRJc2Meta || {},
    component: () => import("/usr/src/app/pages/administration/index.vue").then(m => m.default || m)
  },
  {
    name: "administration-stats",
    path: "stats",
    component: () => import("/usr/src/app/pages/administration/stats.vue").then(m => m.default || m)
  },
  {
    name: "administration-users",
    path: "users",
    component: () => import("/usr/src/app/pages/administration/users/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "beginners-guide",
    path: "/beginners-guide",
    meta: beginners_45guideXY0ITcISlEMeta || {},
    component: () => import("/usr/src/app/pages/beginners-guide.vue").then(m => m.default || m)
  },
  {
    name: "blog-codeId",
    path: "/blog/:codeId()",
    meta: _91codeId_93sD1qCz1POHMeta || {},
    component: () => import("/usr/src/app/pages/blog/[codeId].vue").then(m => m.default || m)
  },
  {
    name: "blog",
    path: "/blog",
    meta: indexgewR7USAlvMeta || {},
    component: () => import("/usr/src/app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "confirm-email",
    path: "/confirm/email",
    meta: emailIf5gHBoGy5Meta || {},
    component: () => import("/usr/src/app/pages/confirm/email.vue").then(m => m.default || m)
  },
  {
    name: "faq",
    path: "/faq",
    meta: faqDkOohHQxeIMeta || {},
    component: () => import("/usr/src/app/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "help",
    path: "/help",
    meta: helpQYzekt5ZcHMeta || {},
    component: () => import("/usr/src/app/pages/help.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexgw2ikJsyhcMeta || {},
    component: () => import("/usr/src/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginRGcvZC480vMeta || {},
    component: () => import("/usr/src/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "news",
    path: "/news",
    meta: newsT2RoX8WRasMeta || {},
    component: () => import("/usr/src/app/pages/news.vue").then(m => m.default || m)
  },
  {
    name: "privicy-policy",
    path: "/privicy-policy",
    meta: privicy_45policy1IBdBc5Nq2Meta || {},
    component: () => import("/usr/src/app/pages/privicy-policy.vue").then(m => m.default || m)
  },
  {
    name: "profile-userId",
    path: "/profile/:userId()",
    meta: _91userId_93s61DvyQgvIMeta || {},
    component: () => import("/usr/src/app/pages/profile/[userId].vue").then(m => m.default || m)
  },
  {
    name: "rates",
    path: "/rates",
    meta: ratesOxfGWLW8w0Meta || {},
    component: () => import("/usr/src/app/pages/rates.vue").then(m => m.default || m)
  },
  {
    name: "registration",
    path: "/registration",
    meta: registrationHsGWJw4mRLMeta || {},
    component: () => import("/usr/src/app/pages/registration.vue").then(m => m.default || m)
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45passwordZej1Ub4tM0Meta || {},
    component: () => import("/usr/src/app/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: "support-chat",
    path: "/support-chat",
    component: () => import("/usr/src/app/pages/support-chat.vue").then(m => m.default || m)
  },
  {
    name: "terms-of-use",
    path: "/terms-of-use",
    meta: terms_45of_45usekooS9dlcBMMeta || {},
    component: () => import("/usr/src/app/pages/terms-of-use.vue").then(m => m.default || m)
  },
  {
    name: "trade",
    path: "/trade",
    component: () => import("/usr/src/app/pages/trade.vue").then(m => m.default || m),
    children: [
  {
    name: "trade-action",
    path: ":action()",
    meta: _91action_93uME32lIpIWMeta || {},
    component: () => import("/usr/src/app/pages/trade/[action].vue").then(m => m.default || m)
  },
  {
    name: "trade-chat",
    path: "chat",
    meta: chathvU3vFyJxPMeta || {},
    component: () => import("/usr/src/app/pages/trade/chat.vue").then(m => m.default || m)
  },
  {
    name: "trade-my-offers",
    path: "my-offers",
    meta: my_45offersh2j4Q6KatBMeta || {},
    component: () => import("/usr/src/app/pages/trade/my-offers.vue").then(m => m.default || m)
  },
  {
    name: ordersUiYkFP5OU7Meta?.name,
    path: "orders",
    meta: ordersUiYkFP5OU7Meta || {},
    component: () => import("/usr/src/app/pages/trade/orders.vue").then(m => m.default || m),
    children: [
  {
    name: "trade-orders-orderId",
    path: ":orderId()",
    meta: _91orderId_93BAO5frnMflMeta || {},
    component: () => import("/usr/src/app/pages/trade/orders/[orderId].vue").then(m => m.default || m)
  },
  {
    name: "trade-orders",
    path: "",
    meta: indexmJAwutfoeWMeta || {},
    component: () => import("/usr/src/app/pages/trade/orders/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "trade-profile",
    path: "profile",
    meta: profileTENEwI8GN2Meta || {},
    component: () => import("/usr/src/app/pages/trade/profile.vue").then(m => m.default || m)
  }
]
  },
  {
    name: userYgr9W7cUghMeta?.name,
    path: "/user",
    meta: userYgr9W7cUghMeta || {},
    component: () => import("/usr/src/app/pages/user.vue").then(m => m.default || m),
    children: [
  {
    name: "user",
    path: "",
    component: () => import("/usr/src/app/pages/user/index.vue").then(m => m.default || m)
  },
  {
    name: "user-notifications",
    path: "notifications",
    component: () => import("/usr/src/app/pages/user/notifications.vue").then(m => m.default || m)
  },
  {
    name: "user-settings",
    path: "settings",
    component: () => import("/usr/src/app/pages/user/settings.vue").then(m => m.default || m)
  },
  {
    name: "user-wallet",
    path: "wallet",
    component: () => import("/usr/src/app/pages/user/wallet.vue").then(m => m.default || m)
  }
]
  }
]